<template>
  <div class="ml-home mb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-xl-2 d-none d-lg-block">
          <MenuLeft :items="listClass" @selectClass="findClass" :has-sub="true"/>
        </div>
        <div class="col-lg-7 col-xl-7">
          <div class="d-none d-lg-block">
            <Banner type="BANNER_HOME_SLIDE_WEB" class="mb-3"/>
            <div class="filter-question d-flex align-items-center">
              <CategorySelectNoLimit placeholder="Môn" class="mr-2" :items="listCategory" @findCategory="findCategory"/>
              <QuestionStatus placeholder="Trạng thái câu hỏi" @findStatus="findStatus"/>
              <button class="ml-auto add-question d-flex align-items-center justify-content-center" @click="addQuestion"><img src="~/mlearn/icons/home/add-question.svg" alt="Đặt câu hỏi" class="mr-2"> Đặt câu hỏi</button>
            </div>
          </div>
          <div class="d-flex d-lg-none align-items-center filter-question">
            <button class="add-question d-flex align-items-center justify-content-center" @click="addQuestion"><img src="~/mlearn/icons/home/add-question.svg" alt="Đặt câu hỏi" class="mr-2"> Đặt câu hỏi</button>
            <div class="action ml-auto pointer" :class="{'active': showFilter}" @click="openFilter">
              <span class="mr-2">Lọc</span>
              <span v-html="require('~/assets/mlearn/icons/home/filter.svg?raw')"/>
            </div>
          </div>
          <div class="new-question text-center" v-if="newQuestion" @click="loadDataNew"><span class="pointer">+ {{ newQuestion }} câu hỏi mới</span></div>
          <div class="list-question">
            <QuestionItem v-for="(item, ind) in questions" :key="ind" :item="item"/>
          </div>
          <NoData v-if="!questions.length"/>
          <div class="question-more text-center" v-if="loadMore && !loading">
            <button @click="$fetch"><img src="~/mlearn/icons/home/arrow-down.svg" alt="" class="mr-2"> Xem thêm</button>
          </div>
        </div>
        <div class="col-lg-3 col-xl-3">
          <FormSearch class="d-none d-lg-block mb-3" :value="query.keyword" @enter="searchData"/>
          <UserInfo class="mb-3"/>
          <Feed class="mb-3"/>
          <Rank :home="true"/>
        </div>
      </div>
    </div>
    <MobileFilter title="Lọc câu hỏi" :show-filter="showFilter" @applyFilter="applyFilter" @closeFilter="closeFilter">
      <div class="block-data">
        <div class="title text-uppercase">Trạng thái câu hỏi</div>
        <div class="row list-item">
          <div class="col-4 item">
            <div class="block-item" :class="{'active': query.question_status === 0}" @click="setQuery('question_status', 0)">Tất cả</div>
          </div>
          <div class="col-4 item">
            <div class="block-item" :class="{'active': query.question_status === 1}" @click="setQuery('question_status', 1)">Đã trả lời</div>
          </div>
          <div class="col-4 item">
            <div class="block-item" :class="{'active': query.question_status === 'desc'}" @click="setQuery('question_status', 'desc')">Mới nhất</div>
          </div>
          <div class="col-4 item">
            <div class="block-item" :class="{'active': query.question_status === 'asc'}" @click="setQuery('question_status', 'asc')">Cũ nhất</div>
          </div>
          <div class="col-4 item">
            <div class="block-item" :class="{'active': query.question_status === 4}" @click="setQuery('question_status', 4)">Hỏi lần đầu</div>
          </div>
        </div>
      </div>
      <div class="block-data">
        <div class="title text-uppercase">Lớp</div>
        <div class="row list-item">
          <div class="col-4 item">
            <div class="block-item" :class="{'active': !query.class_id}" @click="setQuery('class_id', '')">Tất cả</div>
          </div>
          <div class="col-4 item" v-for="item in listClass" :key="'filter' + item.id">
            <div class="block-item" :class="{'active': query.class_id === item.id}" @click="setQuery('class_id', item.id)">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="block-data">
        <div class="title text-uppercase">Môn</div>
        <div class="row list-item">
          <div class="col-4 item">
            <div class="block-item" :class="{'active': !query.category_id}" @click="setQuery('category_id', '')">Tất cả</div>
          </div>
          <div class="col-4 item" v-for="item in listCategory" :key="'filter' + item.id">
            <div class="block-item" :class="{'active': query.category_id === item.id}" @click="setQuery('category_id', item.id)">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </MobileFilter>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import {mapState} from 'vuex'
import MenuLeft from '~/components/mlearn/layout/menu/left.vue'
import Banner from '~/components/mlearn/banner/item.vue'
import CategorySelectNoLimit from '~/components/mlearn/select/category-no-limit.vue'
import QuestionStatus from '~/components/mlearn/select/questionStatus.vue'
import QuestionItem from '~/components/mlearn/question/item.vue'
import UserInfo from '~/components/mlearn/user/info.vue'
import Feed from '~/components/mlearn/feed/index.vue'
import Rank from '~/components/mlearn/rank/index.vue'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import MobileFilter from '~/components/mlearn/mobile/filter.vue'
import Resource from '~/common/api/resource'
import {EventBus} from '~/common/utils/eventBus'

const questionResource = new Resource('qa')
const classResource = new Resource('category/class')
const subjectResource = new Resource('category/subject')
const metaResource = new Resource('config/seo')
export default {
  components: {
    MenuLeft,
    Banner,
    CategorySelectNoLimit,
    QuestionStatus,
    QuestionItem,
    UserInfo,
    Feed,
    Rank,
    FormSearch,
    MobileFilter
  },
  layout: 'mlearn',
  async asyncData ({
    isDev,
    route,
    store,
    env,
    params,
    query,
    req,
    res,
    redirect,
    error
  }) {
    const listClass = await classResource.list()
    const listCategory = await subjectResource.list()
    const meta = await metaResource.list({path: route.path})
    const metaSeo = meta.data
    const structuredData = {}
    return {
      listClass: listClass.data,
      listCategory: listCategory.data,
      questions: [],
      query: {
        class_id: '',
        category_id: '',
        question_status: 0,
        keyword: '',
        limit: 50,
        offset: 0,
        page: 1
      },
      newQuestion: 0,
      metaSeo,
      structuredData,
      loading: false,
      loadMore: true,
      showFilter: false
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  async fetch () {
    if (this.loading) {
      return
    }
    this.loading = true
    const {data} = await questionResource.list(this.query)
    this.loadMore = data.length === this.query.limit
    this.questions.push(...data)
    this.query.offset = this.questions.length
    this.query.page++
    this.loading = false
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: (this.metaSeo?.title || '')
        },
        {
          hid: 'description',
          name: 'description',
          content: (this.metaSeo?.description || '')
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: (this.metaSeo?.keyword || '')
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: (this.metaSeo?.title || '')
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath)
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: (this.metaSeo?.description || '')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: (this.metaSeo?.image || '')
        },
        {
          hid: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          hid: 'twitter:title',
          property: 'twitter:title',
          content: (this.metaSeo?.title || '')
        },
        {
          hid: 'twitter:url',
          property: 'twitter:url',
          content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath)
        },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: (this.metaSeo?.description || '')
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content: (this.metaSeo?.image || '')
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath
        }
      ],
      script: [{
        type: 'application/ld+json',
        json: this.structuredData
      }]
    }
  },
  sockets: {
    NEW_QUESTION (res) {
      const data = res.data
      if (this.query.class_id && this.query.category_id) { // nếu đang chọn cả môn và lớp
        if (data.class_id === this.query.class_id && this.category_id === this.query.category_id) {
          this.newQuestion++
        }
      } else if (this.query.class_id || this.query.category_id) {
        if (data.class_id === this.query.class_id || this.category_id === this.query.category_id) {
          this.newQuestion++
        }
      } else {
        this.newQuestion++
      }
    }
  },
  methods: {
    resetQuery () {
      this.query.offset = 0
      this.query.page = 1
      this.questions = []
      this.loadMore = true
    },
    findClass (classId) {
      this.query.class_id = classId
      this.resetQuery()
      this.$fetch()
    },
    findCategory (categoryId) {
      this.query.category_id = categoryId
      this.resetQuery()
      this.$fetch()
    },
    findStatus (status) {
      if (status === 'asc' || status === 'desc') {
        this.query.sort = status
        this.query.question_status = 2
      } else {
        if (this.query.sort) {
          delete this.query.sort
        }
        this.query.question_status = status
      }
      this.resetQuery()
      this.$fetch()
    },
    loadDataNew () {
      this.resetQuery()
      this.$fetch()
      this.newQuestion = 0
    },
    searchData (keyword = '') {
      this.query.keyword = keyword
      this.$router.push('/tim-kiem?keyword=' + encodeURIComponent(this.query.keyword))
    },
    addQuestion () {
      if (!this.user) { // chưa đăng nhập
        this.$bvModal.show('modal-suggest-sub')
      } else {
        this.$store.dispatch('setShowQuestionDialog', true)
      }
    },
    setQuery (field, value) {
      this.query[field] = value
    },
    openFilter () {
      this.showFilter = true
    },
    closeFilter () {
      this.showFilter = false
    },
    applyFilter () {
      this.closeFilter()
      if (this.query.question_status === 'asc' || this.query.question_status === 'desc') {
        this.query.sort = this.query.question_status
        this.query.question_status = 2
      } else if (this.query.sort) {
        delete this.query.sort
      }
      this.resetQuery()
      this.newQuestion = 0
      this.$fetch()
    },
    handleChangeClassMenu (obj) {
      this.findClass(obj.id)
    }
  },
  created () {
    if (this.user != null) {
      this.findClass(this.user.class_id)
    } else {
      const classID = Cookies.get('interestedClass')
      this.findClass(classID)
    }
    EventBus.$on('handleChangeClassMenu', this.handleChangeClassMenu)
  },
  mounted () {
    const classID = Cookies.get('interestedClass')
    this.findClass(classID)
  },
  destroyed () {
    EventBus.$off('handleChangeClassMenu', this.handleChangeClassMenu)
  }
}
</script>

<style lang="scss" scoped>
.ml-home {
  .filter-question {
    .add-question {
      background: var(--danger);
      box-shadow: 0px 0px 8px rgba(255, 30, 30, 0.5);
      border-radius: 22px;
      font-weight: 500;
      font-size: 16px;
      color: var(--white);
      padding: 10px 30px 10px 25px;
      width: 175px;
      text-align: center;
      @media (max-width: 1366px) {
        padding: 7px 10px;
        width: 175px;
      }
      @media (max-width: 576px) {
        width: 260px;
      }

      &:hover {
        box-shadow: 0px 0px 16px rgba(255, 30, 30, 0.5);
      }
    }
  }

  .new-question {
    margin-top: 16px;
    background: var(--white);
    border: 1px solid #CED4DA;
    border-radius: 8px;
    padding: 10px 0;

    span {
      font-weight: 500;
      font-size: 14px;
      color: #004390;
    }
  }

  .list-question {
    margin-top: 16px;
  }

  .question-more {
    padding: 10px 0 30px;

    button {
      background: #004390;
      border: 1.5px solid #004390;
      border-radius: 40px;
      font-weight: 500;
      font-size: 14px;
      color: var(--white);
      padding: 10px 35px;
      @media (max-width: 576px) {
        padding: 8px 35px;
      }
    }
  }
}
</style>

<template>
  <main class="home-page-main pb-5 pt-2">
    <div class="container container-mobile">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="gioi-thieu">
            <div class="page-title font-weight-bold text-center">CỘNG ĐỒNG HỎI ĐÁP - THÂN THIỆN, TIN CẬY, NHANH CHÓNG</div>
            <div class="block-content">
              <div class="info mb-2">Với phương châm lấy người dùng làm trung tâm, cộng đồng hỏi đáp của mobiEdu sẽ đồng hành giúp các bạn tăng thêm 100% “công lực” cho năm học mới. Chuyên trang hỏi đáp sẽ là nơi giải đáp mọi kiến thức phổ thông của tất cả các bạn học sinh.</div>
              <div class="info">Với phương châm lấy người dùng làm trung tâm, cộng đồng hỏi đáp của mobiEdu sẽ đồng hành giúp các bạn tăng thêm 100% “công lực” cho năm học mới. Chuyên trang hỏi đáp sẽ là nơi giải đáp mọi kiến thức phổ thông của tất cả các bạn học sinh.</div>
            </div>
            <div class="block-content">
              <div class="title">Hỏi đáp MIỄN PHÍ</div>
              <div class="info">Chuyên trang cộng đồng Hỏi đáp của mobiEdu là chuyên trang giải đáp các thắc mắc về kiến thức phổ thông, bài tập liên quan đến kiến thức đã học trong sách giáo khoa, sách bài tập, sách tham khảo liên quan... dành cho các bạn học sinh từ lớp 1 đến lớp 12. Những câu hỏi của các bạn sẽ được hỗ trợ trả lời bởi cộng đồng những người tham gia. Các bạn học sinh sẽ được sử dụng MIỄN PHÍ toàn bộ tính năng hỏi - đáp. Chỉ cần đăng nhập vào chuyên trang hỏi đáp, các bạn sẽ được tham gia cộng đồng, đặt câu hỏi, trả lời câu hỏi, chia sẻ kiến thức.</div>
            </div>
            <div class="block-content">
              <div class="title">Tìm kiếm thông minh</div>
              <div class="info">Các câu hỏi trên cộng đồng hỏi đáp được phân loại theo khối lớp cũng như từng môn học. Việc này sẽ giúp các bạn học sinh dễ dàng tìm kiếm vấn đề mình đang gặp phải cũng như câu trả lời cho vấn đề đó. Tính năng tra cứu từ khoá trên chuyên trang cũng được mobiEdu chú trọng để tặng trải nghiệm của các bạn khi muốn tìm kiếm nội dung nhanh nhất.</div>
            </div>
            <div class="block-content">
              <div class="title">Trở thành "Giám sát viên"</div>
              <div class="info">Không chỉ có vậy, các bạn cũng sẽ được đóng vai trò làm "giám sát viên" với tính năng report các câu hỏi, câu trả lời không phù hợp với nội quy của cộng đồng. Khi báo cáo đúng các vi phạm ở trên trang, bạn sẽ được thưởng một số lượng điểm tương ứng như "phần quà" tinh thần dành cho những "giám sát viên" công tâm.</div>
            </div>
            <div class="block-content">
              <div class="title">Nhận ngay điểm thưởng</div>
              <div class="info">Đặc biệt, khi tham gia các hoạt động trên hoidap.mobiedu.vn như đăng nhập, đặt câu hỏi, trả lời... bạn sẽ nhận được điểm thưởng ngay tức thì và ghi tên mình trên bảng xếp hạng điểm tích lũy. Bảng xếp hạng sẽ được cập nhật thường xuyên theo ngày - tuần - tháng. Định kì, mobiEdu sẽ tổ chức những sự kiện tích luỹ điểm đặc biệt để cuộc đua tích điểm thêm phần hấp dẫn. Các điểm thưởng này sẽ giúp các bạn có nhiều cơ hội đổi quà hơn trong tương lai.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Resource from '~/common/api/resource'
const metaResource = new Resource('config/seo')
export default {
  components: {
  },
  layout: 'thu-vien',
  async asyncData ({ route }) {
    // if (store.state.isMobile) {
    //   return redirect('/home/mobile')
    // }
    const meta = await metaResource.list({ path: route.path }).catch(() => {})
    const metaSeo = meta?.data || null
    const structuredData = {}
    return {
      metaSeo,
      structuredData
    }
  },
  data () {
    return {
      isMobile: false
    }
  },
  head () {
    if (!this.metaSeo) {
      return {
        link: [
          { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath },
          { href: 'https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap', style: 'text/css', rel: 'stylesheet' }
        ]
      }
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath },
        { href: 'https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap', style: 'text/css', rel: 'stylesheet' }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  mounted () {
    this.isMobile = window.innerWidth <= 520
  }
}
</script>

<style lang="scss">
  .home-page-main{
    font-family: 'Sarabun', sans-serif;
    min-height: 500px;
    .gioi-thieu{
      background: var(--white);
      box-shadow: 0px 2px 4px 0px #AEAEAE57;
      border-radius: 4px;
      padding:24px 40px;
      border: 1px solid #004390;
      @media (max-width:576px) {
        padding: 16px;
        box-shadow: none;
      }
      @media (max-width:1366px) {
        padding: 18px;
      }
      .page-title{
        font-size: 20px;
        @media (max-width:576px) {
          font-size: 16px;
        }
        @media (max-width:1366px) {
          font-size: 18px;
        }
      }
      .block-content{
        counter-reset: myCounter;
        padding-top:25px;
        @media (max-width:576px) {
          padding-top:16px;
        }
        @media (max-width:1366px) {
          padding-top:18px;
        }
        .title{
          text-align: left;
          //text-transform: uppercase;
          font-weight: 700;
          //position: relative;
          font-size: 18px;
          padding-left:17px;
          margin-bottom: 7px;
          @media (max-width:576px) {
            font-size: 15px;
            padding-left:10px;
            margin-bottom: 4px;
          }
          @media (max-width:1366px) {
            font-size: 15px;
            padding-left:10px;
            margin-bottom: 4px;
          }
          //&::before{
          //  content: '';
          //  position: absolute;
          //  left: 0;
          //  background: #004390;
          //  top: 50%;
          //  transform: translateY(-50%);
          //  width: 6px;
          //  height: 28px;
          //  @media (max-width:576px) {
          //    width: 3px;
          //    height: 20px;
          //  }
          //  @media (max-width:1366px) {
          //    width: 3px;
          //    height: 20px;
          //  }
          //}
        }
        .info{
          word-break: break-word !important;
          word-wrap: break-word !important;
          color: #65676B;
          font-size: 16px;
          padding-left:16px;
          @media (max-width:576px) {
            font-size: 14px;
            padding-left:10px;
          }
          @media (max-width:1366px) {
            font-size: 14px;
            padding-left:10px;
          }
          //&::before{
          //  counter-increment: myCounter;
          //  content: counter(myCounter) ". ";
          //}
        }
      }
    }
  }
</style>
